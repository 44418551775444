import { Col, Row, Space } from "antd";
import React, { useState } from "react";

const FiveTabCon = ({ describes = [], img, show }) => {
  return (
    <div className="section-five-item" style={{ display: `${show ? 'flex' : 'none'}` }}>
      <div>
        {
          describes.map((describe, index) => <div key={index} style={{ marginBottom: '12px' }}><strong>{describe[0]}</strong>{describe[1]}</div>)
        }
      </div>
      <img src={img} style={{ width: '100%' }} />
    </div>
  );
}


const SectionFive = () => {
  const [tab, setTab] = useState(0);

  return (
    <div style={{ marginTop: '50px' }}>
      <Row justify="center" className="section-five-tabs" style={{ textAlign: 'center' }} gutter={[30, 30]}>
        <Col md={6} sm={24} xs={24}>
          <Row>
            <Col md={24} sm={12} xs={24}>
              <div className={`section-five-tab ${tab === 0 ? 'active' : ''}`} onClick={() => setTab(0)}>实现从0→1→N→ N²的迭代进化</div>
            </Col>
            <Col md={24} sm={12} xs={24}>
              <div className={`section-five-tab ${tab === 1 ? 'active' : ''}`} onClick={() => setTab(1)}>3D复杂形状缺陷检测系统</div>
            </Col>
            <Col md={24} sm={12} xs={24}>
              <div className={`section-five-tab ${tab === 2 ? 'active' : ''}`} onClick={() => setTab(2)}>精密金属加工工艺缺陷验证实验台</div>
            </Col>
            <Col md={24} sm={12} xs={24}>
              <div className={`section-five-tab ${tab === 3 ? 'active' : ''}`} onClick={() => setTab(3)}>半导体工艺缺陷视觉验证实验台</div>
            </Col>
            <Col md={24} sm={12} xs={24}>
              <div className={`section-five-tab ${tab === 4 ? 'active' : ''}`} onClick={() => setTab(4)}>铸造行业缺陷智能检测系统</div>
            </Col>
          </Row>
        </Col>
        <Col md={18} sm={24} xs={24} className="section-five-tab-con">
          <FiveTabCon describes={[
            ['·概念方案化，创新突破（从0到1）。', '联合山东省内众多专精特新企业、领军企业等共建机器视觉概念验证联合实验室，不断探索机器视觉在多行业的首台套开发合作机制及高效落地方案。'],
            ['·方案产品化，裂变迭代（从1到N）。', '聚焦机器人视觉、半导体、精密金属加工、精密铸造等战略性新型产业或山东省优势产业，对机器视觉在一些标准化场景下进行产品化开发和落地。'],
            ['·产品平台化，智能一体（从N到N²）。', '构建可复用、可重构的机器视觉大脑，进一步提高模型的通用性，降低模型开发成本及开发周期，赋能千行百业。']
          ]} img={require('../../../img/innovation/聚焦行业1.png')} show={tab === 0} />
          <FiveTabCon describes={[
            ['·聚焦行业：', '涂胶工艺缺陷检测，用于汽车及零部件、动力电池、航空航天等行业。'],
            ['·方案亮点：', '国内首创基于点云的3D深度学习软件，构建了专门胶型缺陷的泛在学习网络，大幅提高了胶型缺陷识别的准确度，实现了物体复杂形状检测的“国产替代” 和“弯道超车” 。'],
            ['·已落地场景：', '比亚迪、长安汽车、广汽丰田、东风日产、小米汽车等。']
          ]} img={require('../../../img/innovation/聚焦行业2.png')} show={tab === 1} />
          <FiveTabCon describes={[
            ['·聚焦行业：', '金属精密加工等工艺缺陷检测，用于3C、光伏、电机、仪器仪表等行业。'],
            ['·方案亮点：', '率先突破国外顶级客户检测需要，实现了微米级缺陷的高精准检测。'],
            ['·已落地场景：', '某头部3C厂商、隆基绿能等。'],
          ]} img={require('../../../img/innovation/聚焦行业3.png')} show={tab === 2} />
          <FiveTabCon describes={[
            ['·聚焦行业：', '无图案/有图案晶圆缺陷、芯片封装前中后道工艺缺陷检测，用于半导体行业缺陷检测'],
            ['·方案亮点：', '基于AI深度学习对半导体缺陷检测进行全新技术创新，实现对半导体关键检测设备的“国产替代”'],
            ['·已落地场景：', '某晶圆厂、封装厂等。'],
          ]} img={require('../../../img/innovation/聚焦行业4.png')} show={tab === 3} />
          <FiveTabCon describes={[
            ['·聚焦行业：', '精密铸造行业缺陷检测'],
            ['·方案亮点：', '具备铸造砂型的高精度、高速、全类型的缺陷自动检测的能力，行业首台套'],
            ['·已落地场景：', '潍柴动力等'],
          ]} img={require('../../../img/innovation/聚焦行业5.png')} show={tab === 4} />
        </Col>
      </Row>
    </div>
  )
}

export default SectionFive;