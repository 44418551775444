import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import './index.css'
import PageHeader from '../../components/PageHeader';
import { Switch, Route, useParams, useHistory, useRouteMatch } from 'react-router-dom';
import Contact from './components/Contact';
import ContactUs from './components/ContactUs';
import Company from './components/Company';

const About = () => {
    let { id } = useParams();
    const [page, setPage] = useState(1);
    let match = useRouteMatch();

    let history = useHistory();
    const category = [
        { label: '公司简介', value: 'company', to: '/about/company' },
        { label: '招贤纳才', value: 'joinUs', to: '/about/joinUs' },
        { label: '联系我们', value: 'us', to: '/about/us' },
    ]

    useEffect(() => {
        if (!category.length || id) return;
        history.replace(category[0].to)
    }, [id, category, history])

    return (
        <div className="about">
            <Breadcrumb breadcrumbClass="breadcrumb_area breadcrumb_area_three" imgName="about/banner.png" />
            <PageHeader items={category}/>
            <Switch>
                <Route path={`${match.path}/company`}>
                    <Company />
                </Route>
                <Route path={`${match.path}/joinUs`}>
                    <Contact />
                </Route>
                <Route path={`${match.path}/us`}>
                    <ContactUs />
                </Route>
            </Switch>

        </div>
    )
}

export default About;