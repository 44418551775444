import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Reveal from 'react-reveal/Reveal';

const HomeItem2 = ({ img, title, to, effect }) => {
  // key 目地是强制刷新dom。为了解决动画不执行的问题
  const [key, setKey] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      setKey(() => 1)
    }, 0)
  }, [])

  return (
    <Reveal effect={effect} key={key}>
      <Link className="home_item2" to={to}>
        <div className="home_item2_con">
          <img src={img} alt="" width="100%" />
          <p className="home_item2_title">
            {title}
          </p>
        </div>
      </Link>
    </Reveal>
  )
}

export default HomeItem2;
