import React from "react";
import Title from "../../../components/Title";
import { useContactData } from "../../../hook/useMenuData";
import { Skeleton } from "antd";

const ContactUs = () => {
  const { data = {}, isLoading } = useContactData();

  return (
    <div className="contact-us container page-container">
      <Title>联系我们</Title>
      <div className="contactUs-card">
        {
          isLoading ? <Skeleton active />  : (
            <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
          )
        }
      </div>
    </div>
  )
}

export default ContactUs;