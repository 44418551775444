import React, { useEffect, useMemo } from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import './index.css'
import PageHeader from '../../components/PageHeader';
import { useParams, useHistory } from 'react-router-dom';
import SolutionItems from './components/SolutionItems';
import { useCategory } from '../../hook/useBaseData';

const Solution = () => {
    let { id } = useParams();
    let history = useHistory();
    const { data: category = [] } = useCategory(3, {
        formatResult: (data) => {
            return data.map(item => ({ label: item.name, value: item.id, to: `/solution/${item.id}`, ...item }))
        }
    });

    const curCategory = useMemo(() => {
        if (!category.length || !id) return;
        return category.find(item => item.value === id);
    }, [category, id])


    const categoryIds = useMemo(() => {
        if (!category.length || !curCategory) return 0;
        if (!curCategory._child) return id;
        return curCategory._child.map(item => item.id)
    }, [category, curCategory, id])

    const getCategory = (childId) => {
        if (!curCategory._child) return {};
        return curCategory._child.find(item => item.id === childId)
    }

    useEffect(() => {
        if (!category.length || id) return;
        history.replace(category[0].to)
    }, [id, category])

    return (
        <div>
            <Breadcrumb breadcrumbClass="breadcrumb_area breadcrumb_area_three" imgName="banner/solution.png" />
            <PageHeader items={category} />
            <section className="experts_team_area sec_pad" key={id}>
                <div className="container">
                    {
                        Array.isArray(categoryIds) ? categoryIds.map((item, index) => (
                            <div key={item}>
                                <div className="f_size_30 solution-category-title">{getCategory(item).name}</div>
                                <div className="divider solution-divider mb-30" />
                                <SolutionItems categoryId={categoryIds} />
                            </div>
                        )) : <SolutionItems key="categoryIds" categoryId={categoryIds} />
                    }
                </div>
            </section>
        </div>
    )
}

export default Solution;