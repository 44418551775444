import React, { useEffect } from 'react';
import './index.css'
import { Col, Row, Input, Button, Form, message } from 'antd';
import { useNote } from '../../hook/useNote';

const Note = () => {
    const { mutate, isLoading, error, isSuccess } = useNote()
    const [messageApi, contextHolder] = message.useMessage();
    const [form] = Form.useForm();

    const onSubmit = async (values) => {
        const bodyFormData = new FormData();
        bodyFormData.append('name', values.name || '');
        bodyFormData.append('company', values.company || '');
        bodyFormData.append('mobile_phone', values.mobile_phone || '');
        bodyFormData.append('email', values.email || '');
        bodyFormData.append('content', values.content || '');
        mutate(bodyFormData);
    }

    useEffect(() => {
        if (error) {
            messageApi.open({
                type: 'error',
                content: error,
            });
        }
    }, [error])

    useEffect(() => {
        if (isSuccess) {
            messageApi.open({
                type: 'success',
                content: '提交成功',
            });
            form.resetFields();
        }
    }, [isSuccess])

    return (
        <div className="container note-container text-center">
            {contextHolder}
            <h1>在线留言</h1>
            <div className="divider note-divider"></div>
            <Form onFinish={onSubmit} className="text-left" form={form}>
                <Row gutter={[24, 0]} className="mt_50">
                    <Col sm={12} xs={24}>
                        <Form.Item name="name" rules={[{ required: true, message: '姓名不能为空' }]}>
                            <Input placeholder="姓名" />
                        </Form.Item>
                    </Col>
                    <Col sm={12} xs={24}>
                        <Form.Item name="company" rules={[{ required: true, message: '公司不能为空' }]}>
                            <Input placeholder="公司" />
                        </Form.Item>
                    </Col>
                    <Col sm={12} xs={24}>
                        <Form.Item name="mobile_phone">
                            <Input placeholder="手机号" />
                        </Form.Item>
                    </Col>
                    <Col sm={12} xs={24}>
                        <Form.Item name="email">
                            <Input placeholder="邮箱" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="content">
                            <Input.TextArea placeholder="请简单描述您要反馈的问题或建议，我们将有专员与您联系。" style={{ height: 240, resize: 'none' }} />
                        </Form.Item>
                    </Col>
                    <Col span={24} className="text-center">
                        <Button type="primary" loading={isLoading} className="note-btn" htmlType="submit">提交</Button>
                    </Col>
                </Row>

            </Form>
            <div className="mt_40 note-hint">服务热线：0755-21078607、15900413862</div>
        </div>
    )
}

export default Note;