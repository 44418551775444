import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Reveal from 'react-reveal/Reveal';

const HomeItem = ({ img, title, describe, to }) => {
  // key 目地是强制刷新dom。为了解决动画不执行的问题
  const [key, setKey] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      setKey(() => 1)
    }, 0)
  }, [])

  return (
    <Link className="home_item_con" to={to} key={key}>
      <Reveal bottom cascade>
        <div>
          <img src={img} alt="" width="100%" />
          <div className="home_item_des">
            <div className="home_item_des_c">
              <div className="home_item_t">
                <div className="home_item_title">{title}</div>
              </div>
              <div className="home_item_d">
                <div className="home_item_describe">
                  <p>{describe}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Reveal>
    </Link>
  )
}

export default HomeItem;
