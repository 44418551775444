import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

// 广告
export const useAd = () => {
  return useQuery({
    queryKey: ['ad'],
    queryFn: () => {
      return axios.get(`${window.webConfig.api}/Api/index/ad`).then(res => {
        if (res.data.code !== 1) {
          throw new Error('No ad data');
        }
        return res.data.data;
      })
    },
  });
};


// 分类信息
export const useCategory = (type, { formatResult } = {}) => {
  return useQuery({
    queryKey: ['category', type],
    queryFn: () => {
      return axios.get(`${window.webConfig.api}/Api/index/category`, { params: { type } }).then(res => {
        if (res.data.code !== 1) {
          throw new Error('No menu data');
        }
        if (formatResult) return formatResult(res.data.data)
        return res.data.data;
      })
    },
  });
};

const informationA = (params) => {
  return axios.get(`${window.webConfig.api}/Api/index/information`, { params: { r: 16, ...params } }).then(res => {
    if (res.data.code !== 1) {
      throw new Error('No menu data');
    }
    return res.data.data;
  })
}

export const useInformation = ({ category_id = '', ...params }, options,) => {
  return useQuery({
    queryKey: ['information', { category_id, ...params }],
    queryFn: (...arg) => {
      if (typeof category_id === 'string') {
        return informationA({ category_id, ...params })
      }
      return Promise.all(category_id.map(item => informationA({ category_id: item, ...params })))
    },
    ...options
  });
};

export const useDetailData = (id, { formatResult } = {}) => {
  return useQuery({
    queryKey: ['detail', id],
    queryFn: () => {
      return axios.get(`${window.webConfig.api}/Api/index/detail`, { params: { id } }).then(res => {
        if (res.data.code !== 1) {
          throw new Error('No menu data');
        }
        if (formatResult) return formatResult(res.data.data)
        return res.data.data;
      })
    },
  });
};