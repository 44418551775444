import React from 'react';

const DetailItem = ({ title, content }) => {
  return (
    <div>
      {
        !!title ? (
          <>
            <h1 className="text-center mb_15 mt_60">{title}</h1>
            <div className="divider detail-divider mx-auto"></div>
          </>
        ) : null
      }
      <div dangerouslySetInnerHTML={{ __html: content }} ></div>
    </div>
  )
}

export default DetailItem;