import { Button, Col, Popover, Row } from "antd";
import React from "react";

const DetailCard = ({ data }) => {
  return (
    <Row gutter={30}>
      <Col className="detail-card-l" xl={7} sm={8} xs={24}>
        <div className="detail-card-img" style={{ backgroundImage: `url(${data.pic_url})` }}></div>
      </Col>
      <Col xl={13} sm={16} xs={24}>
        <div className="d-flex flex-column detail-card-r">
          <div className="flex-fill">
            <h2>{data.title}</h2>
            <div className="detail-card-rc">{data.describe}</div>
          </div>
          <Popover content={<div className="text-center"><div>{data.landline}</div><div>{data.mobile_phone}</div></div>}>
            <Button type="primary" className="detail-card-btn">立即咨询</Button>
            {/* <button type="button" class="btn detail-card-btn">立即咨询</button> */}
          </Popover>
        </div>
      </Col>
    </Row>
  )
}

export default DetailCard;