import React, { useEffect, useState } from 'react';
import './index.css'
import { Row, Col } from 'antd'
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Mousewheel } from 'swiper';
import Footer from '../../components/Footer/Footer';
import HomeItem from './components/HomeItem';
import HomeItem2 from './components/HomeItem2';
import HomeAd from './components/HomeAd';
import { useMenuData } from '../../hook/useMenuData';

export const Home = () => {
    SwiperCore.use([Pagination, Mousewheel]);
    const [swiperRef, setSwiperRef] = useState(null);

    useEffect(() => {
        const backTopBtn = document.getElementsByClassName('backTop')[0]
        const slideToTop = () => {
            swiperRef.slideTo(0, 1000)
        }
        backTopBtn.addEventListener('click', slideToTop);
        return () => backTopBtn.removeEventListener('click', slideToTop)
    }, [swiperRef])

    console.log('swiperRef :>> ', swiperRef);

    const { data } = useMenuData();

    return (
        <div className="body_wrapper home_body">
            <Swiper
                onSwiper={setSwiperRef}
                direction="vertical"
                slidesPerView="auto"
                spaceBetween={0}
                mousewheel
                speed={1000}
                className="home-swiper"
                observer={true} //修改swiper自己或子元素时，自动初始化swiper(如果数据是请求下来的一定要重新初始化)
                observeParents={true} //修改swiper的父元素时，自动初始化swiper(如果数据是请求下来的一定要重新初始化)
            >
                <SwiperSlide>
                    <HomeAd />
                </SwiperSlide>
                <SwiperSlide>
                    <div className="home_banner home_banner2 d-flex flex-column justify-content-center align-items-center text-center">
                        <h1 className="f_size_40 home_title">AI算法检测平台 & 3D视觉检测平台</h1>
                        <Row gutter={20} justify="center" className="container">
                            <Col md={12} sm={18} xs={24}>
                                <HomeItem to="/product/4/5" img={require('../../img/home/配图2-1.png')} title="IM AI算法检测平台" describe="集成智能深度学习与传统机器视觉算法功能，能够满足机器人定位、尺寸测量、缺陷检测等机器视觉应用。检测平台软件封装完善，操作便利，拖拽式编程，可满足无编程经验人员的操作应用。平台软件兼容多场景检测，检测逻辑可自由配置，完成复杂项目。独立的标注训练软件，可离线完成模型训练，高效实现检测的迭代升级。" />
                            </Col>
                            <Col md={12} sm={18} xs={24}>
                                <HomeItem to="/product/4/11" img={require('../../img/home/配图2-2.png')} title="IM 3D检测平台" describe="具备轮廓线检测、三维点云数据处理、二维深度图识别处理等功能，能够满足缺陷检测、定位，以及高精度测量等应用场景。3D与2D结合，使得软件具备操作简易、精度高的特点。支持多种标准通信协议，可实现与多种设备实现通讯对接，支持常见品牌3D相机，实现硬件选型自由。" />
                            </Col>
                        </Row>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="home_banner home_banner3 d-flex flex-column justify-content-center align-items-center text-center">
                        <h1 className="f_size_40 home_title">光学成像系统</h1>
                        <Row gutter={20} justify="center" className="container">
                            <Col md={12} sm={18} xs={24}>
                                <HomeItem to="/product/3/3" img={require('../../img/home/配图3-1.png')} title="自研抗反光特种镜头" describe="可有效解决针对高反光材质缺陷成像问题及最大程度过滤干扰光波，有效解决检测物体表面背景复杂成像问题" />
                            </Col>
                            <Col md={12} sm={18} xs={24}>
                                <HomeItem to="/product/3/12" img={require('../../img/home/配图3-2.png')} title="自研辅助成像光学模组" describe="可专门针对极微小内孔，内螺纹进行内360°侧壁检测。目前成为业内首家达成微小螺纹孔（＜M1）内螺纹360°侧壁缺陷全检" />
                            </Col>
                        </Row>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="home_banner home_banner4 d-flex flex-column justify-content-center align-items-center text-center">
                        <h1 className="f_size_40 home_title">AI视觉控制器</h1>
                        <Row gutter={20} justify="center" className="container">
                            <Col md={12} sm={18} xs={24}>
                                <HomeItem to="/product/3/4" img={require('../../img/home/配图4.png')} title="自研AI视觉控制器" describe="针对AI算法在工业视觉领域对视觉控制器的性能要求高、市场普遍视觉控制器性能不够、硬件兼容性差、故障率较多等痛点，深圳智机基于对自研AI算法的掌握，充分理解配套AI视觉控制器需要具备的性能，整合上游硬件商资源，结合自有AI项目现场磨合经验，形成自有品牌-7系列AI视觉控制器 IM-ZJ701（S）-AI，目前已经批量应用于全球顶级手机客户和全球顶级新能源汽车客户项目现场。" />
                            </Col>
                        </Row>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="home_banner home_banner5 d-flex flex-column justify-content-center align-items-center text-center">
                        <h1 className="f_size_40 home_title">AI精密金属检测案例-解决行业复杂难题</h1>
                        <Row gutter={[28, 10]} justify="center" className="container">
                            <Col md={12} sm={18} xs={24}>
                                <HomeItem2 to="/solution/5/8" effect="fadeInLeft" img={require('../../img/home/配图5-1.png')} title="超高反光、受反光干扰严重、缺陷细微且种类繁多识别难度大的镜面金属圆环。" />
                            </Col>
                            <Col md={12} sm={18} xs={24}>
                                <HomeItem2 to="/solution/5/7" effect="fadeInRight" img={require('../../img/home/配图5-2.png')} title="尺寸、造型复杂检测弧面多，缺陷形态变化多成像难度大的表面为不锈钢反光材质的金属产品。" />
                            </Col>
                            <Col md={12} sm={18} xs={24}>
                                <HomeItem2 to="/solution/5/6" effect="fadeInLeft" img={require('../../img/home/配图5-3.png')} title="产品表面为钛合金材质，尺寸较薄且缺陷种类多、变化大成像困难。" />
                            </Col>
                            <Col md={12} sm={18} xs={24}>
                                <HomeItem2 to="/solution/5/14" effect="fadeInRight" img={require('../../img/home/配图5-4.png')} title="产品为铝合金材质表面多为异形面结合且缺陷分部及种类过多，成像难度巨大。" />
                            </Col>
                        </Row>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="home_banner home_banner6 d-flex flex-column justify-content-center align-items-center text-center">
                        <h1 className="f_size_40 home_title">3D检测案例-解决行业难题</h1>
                        <Row gutter={[28, 10]} justify="center" className="container">
                            <Col md={12} sm={18} xs={24}>
                                <HomeItem2 to="/solution/5/13" effect="fadeInLeft" img={require('../../img/home/配图6-1.png')} title="产品为不锈钢反光复杂多面结构、测量尺寸较多、背景复杂、成像干扰较大。" />
                            </Col>
                            <Col md={12} sm={18} xs={24}>
                                <HomeItem2 to="/solution/6/15" effect="fadeInRight" img={require('../../img/home/配图6-2.png')} title="胶水处于元器件周围、形态不一致、溢胶与断胶形态类似，解决黑底黑胶形态相似断胶胶水检测。" />
                            </Col>
                            <Col md={12} sm={18} xs={24}>
                                <HomeItem2 to="/solution/6/16" effect="fadeInLeft" img={require('../../img/home/配图6-3.png')} title="胶水位置多反光严重、胶线复杂、干扰多容易产生检测死角，通过算法去噪实现高速、精准检测。" />
                            </Col>
                            <Col md={12} sm={18} xs={24}>
                                <HomeItem2 to="/solution/6/17" effect="fadeInRight" img={require('../../img/home/配图6-4.png')} title="胶条细小繁多、胶线复杂反光、位置干扰较多，通过3D线扫相机结合降噪算法同时对多个RTV胶完成检测。" />
                            </Col>
                        </Row>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="home_banner home_banner7 d-flex flex-column justify-content-center align-items-center text-center">
                        <h1 className="f_size_40 text-white mb-30">关于智机</h1>
                        <div className="row container home_banner7_detail mx-0 px-0">
                            <div className="col-lg-6 col-md-6 home_banner5_table_l d-flex flex-column justify-content-center align-items-center text-center">
                                {/* <p className="f_size_20 text-white">致力成为</p> */}
                                <h1 className="text-white" style={{ margin: '30px 0' }}>让千万机器带上眼睛 重构机器的视觉大脑和群体智能 引领机器人智能革命</h1>
                                {/* <p className="f_size_16 text-white">奋斗、当责、求新、高效</p> */}
                            </div>
                            <table className="table table-bordered col-lg-6 col-md-6 mb-0">
                                <tbody>
                                    <tr>
                                        <td className="home_banner7_td">
                                            <div className="home_banner7_item">
                                                <h1 className="home_banner7_item_t">1000+</h1>
                                                <div className="home_banner7_item_d">方案设计</div>
                                            </div>
                                        </td>
                                        <td className="home_banner7_td">
                                            <div className="home_banner7_item">
                                                <h1 className="home_banner7_item_t">75%+</h1>
                                                <div className="home_banner7_item_d">75%+员工为研发技术人员</div>
                                            </div>
                                        </td>
                                        <td className="home_banner7_td">
                                            <div className="home_banner7_item">
                                                <h1 className="home_banner7_item_t">50+</h1>
                                                <div className="home_banner7_item_d">50余项发明、软著专利</div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="home_banner7_td">
                                            <div className="home_banner7_item">
                                                <h1 className="home_banner7_item_t">10+</h1>
                                                <div className="home_banner7_item_d">10余年AI检测、3D视觉经验积累</div>
                                            </div>
                                        </td>
                                        <td className="home_banner7_td">
                                            <div className="home_banner7_item">
                                                <h1 className="home_banner7_item_t">10+</h1>
                                                <div className="home_banner7_item_d">10余项认证测试及登记证书</div>
                                            </div>
                                        </td>
                                        <td className="home_banner7_td">
                                            <div className="home_banner7_item">
                                                <h1 className="home_banner7_item_t">4项</h1>
                                                <div className="home_banner7_item_d">4项国家级、省级政府荣誉</div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </SwiperSlide>
                {
                    !!data && (
                        <SwiperSlide style={{ height: 'auto' }}>
                            <Footer fClass="pt_150" />
                        </SwiperSlide>
                    )
                }
            </Swiper>
        </div>
    )
}