import React from "react";

const SectionTwoCard = ({ title1, title2, img }) => {
  return (
    <div className="section-two-card">
      <div className="section-two-card-img" style={{ backgroundImage: `url(${img})` }}>
      </div>
      <div className="section-two-card-title">
        <div className="section-two-card-title1">{title1}</div>
        <div className="section-two-card-title2">{title2}</div>
      </div>
    </div>
  );
}

export default SectionTwoCard;
