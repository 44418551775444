import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import './index.css'
import PageHeader from '../../components/PageHeader';
import { useParams, useHistory } from 'react-router-dom';
import ProductItem from './components/ProductItem';
import { useCategory, useInformation } from '../../hook/useBaseData';
import { Pagination, Button, Skeleton } from 'antd';

const Product = () => {
    let { id } = useParams();
    const [page, setPage] = useState(1);
    let history = useHistory();
    const { data: category = [] } = useCategory(2, {
        formatResult: (data) => {
            return data.map(item => ({ label: item.name, value: item.id, to: `/product/${item.id}` }))
        }
    });

    useEffect(() => {
        setPage(1)
    }, [id])

    const { data: products = [], isLoading } = useInformation({ category_id: id, r: 16, p: page, type: 2 }, { enabled: !!id })

    useEffect(() => {
        if (!category.length || id) return;
        history.replace(category[0].to)
    }, [id, category, history])

    return (
        <div>
            <Breadcrumb breadcrumbClass="breadcrumb_area breadcrumb_area_three" imgName="banner/product.png" />
            <PageHeader items={category}/>
            { isLoading && <Skeleton active className="container" style={{ margin: '30px auto' }}/> }
            <section className="experts_team_area sec_pad">
                <div className="container">
                    <div className="row">
                        {
                            products && products.list && products.list.map(item => (
                                <div className="col-lg-3 col-sm-6" key={item.id}>
                                    <ProductItem data={item} />
                                </div>
                            ))
                        }
                    </div>
                    <Pagination className="text-center mt_20" onChange={p => setPage(p)} pageSize={16} current={page} total={products.count || 0} showSizeChanger={false} itemRender={(page, type, originalElement) => {
                        if (type === 'prev') return <Button type="text">上一页</Button>
                        if (type === 'next') return <Button type="text">下一页</Button>
                        return originalElement
                    }}/>
                </div>
            </section>
        </div>
    )
}

export default Product;