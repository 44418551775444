import { Button, Col, Popover, Row } from "antd";
import React from "react";

const DetailCard = ({ data }) => {
  return (
    <Row gutter={0} className="solution-detail-card">
      <Col className="detail-card-l" lg={10} sm={8} xs={24}>
        <img src={data.pic_url} width="100%"/>
        {/* <div className="detail-card-img" style={{ backgroundImage: `url(${data.pic_url})` }}></div> */}
      </Col>
      <Col lg={14} sm={16} xs={24}>
        <div className="d-flex flex-column solution-detail-card-r">
          <h2 className="solution-detail-card-t">{data.title}</h2>
          <div className="solution-detail-card-d">{data.describe}</div>
        </div>
      </Col>
    </Row>
  )
}

export default DetailCard;