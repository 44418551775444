import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

export const useMenuData = () => {
  return useQuery({
    queryKey: ['menu'],
    queryFn: () => axios.get(`${window.webConfig.api}/Api/index/menu`).then(res => {
      if (res.data.code !== 1) {
        throw new Error('No menu data');
      }
      return res.data.data;
    }),
  });
};

export const useContactData = () => {
  return useQuery({
    queryKey: ['contact'],
    queryFn: () => axios.get(`${window.webConfig.api}/Api/index/contact`).then(res => {
      if (res.data.code !== 1) {
        throw new Error('No contact data');
      }
      return res.data.data;
    }),
  });
};
