import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

const ProductItem = ({ data }) => {
  let { id } = useParams();
  let history = useHistory();

  const openDetail = () => {
    history.push(`/product/${id}/${data.id}`)
  }

  return (
    <div className="mb-3 product-item" onClick={() => openDetail()}>
      <div className="product-item-top">
        <div className="product-item-img" style={{ backgroundImage: `url(${data.pic_url})` }}></div>
      </div>
      <div className="product-item-title text-center">
        {data.title}
      </div>
    </div>
  )
}

export default ProductItem;