import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

const Item = ({ data }) => {
  let { query } = useParams();
  const history = useHistory();
  // 文本上标记搜索的结果
  const label = (text) => {
    const regex = new RegExp(query, 'gi');
    return (text || '').replace(regex, '<mark class="search-mark">$&</mark>');
  }

  const openDetail = () => {
    history.push(`/${['', 'news', 'product', 'solution'][data.type]}/0/${data.id}`)
  }

  return (
    <div className="search-item" onClick={() => openDetail()}>
      <div dangerouslySetInnerHTML={{ __html: label(data.title) }}></div>
      <div className="search-item-describe" dangerouslySetInnerHTML={{ __html: label(data.describe) }}></div>
    </div>
  )
}

export default Item;