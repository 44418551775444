import React from 'react';
import Title from '../../../components/Title';

const SectionDetail = ({ title, content }) => {
  return (
      <div className="section-detail">
          <Title>{title}</Title>
          {content && <div className="innovation-section-detail">{content}</div>}
      </div>
  )
}

export default SectionDetail;