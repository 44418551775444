import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, useHistory } from "react-router-dom";
/*------ Pages-----*/
import { Home } from "./Pages/Home/index";
import ScrollToTopRoute from "./ScrollToTopRoute";
import CustomNavbar from './components/Header/CustomNavbar';
import Footer from './components/Footer/Footer';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { Affix, ConfigProvider, Popover } from 'antd';
import Product from "./Pages/Product";
import Solution from "./Pages/Solution";
import News from "./Pages/News";
import Note from "./Pages/Note";
import ProductDetail from "./Pages/Product/Detail";
import SolutionDetail from "./Pages/Solution/Detail";
import NewsDetail from "./Pages/News/Detail";
import { useContactData } from './hook/useMenuData'
import Innovation from "./Pages/Innovation";
import About from "./Pages/About";
import NotFound from "./Pages/404";
import Search from "./Pages/Search";

const queryClient = new QueryClient()

const Layout = ({ children }) => {
  const { data = {} } = useContactData();

  const history = useHistory();
  const openNote = () => {
    history.push('note')
  }

  // 回到顶部
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <div>
      <CustomNavbar cClass="custom_container p0" />
      {children}
      <Affix style={{ position: 'absolute', top: 0, right: 0 }} offsetTop={200} onChange={(affixed) => console.log(affixed)}>
        <div>
          <div className="affix-btn backTop" onClick={scrollToTop}>
            <div className="affix-img affix-img-top"></div>
          </div>
          <div className="affix-btn">
            <div className="affix-btn-con affix-btn-note" onClick={() => openNote()}>
              <div className="affix-img affix-img-note"></div>
              <div className="affix-hide">在线留言</div>
            </div>
          </div>
          <div className="affix-btn">
            <a className="affix-btn-con affix-btn-phone" href="tel:0755-21078607">
              <div className="affix-img affix-img-phone"></div>
              <div className="affix-hide">{data.landline}</div>
            </a>
          </div>
          <div className="affix-btn">
            <Popover content={<div><div className="layout-r-qrcode" style={{ backgroundImage: `url(${data.qr_code})` }}></div><div className="text-center">客服微信</div></div>} rootClassName="affix-popver" placement="left">
              <div className="affix-img affix-img-qrcode"></div>
            </Popover>
          </div>
        </div>
      </Affix>
      {
        history.location.pathname !== '/' && <Footer fClass="pt_150" />
      }
    </div>
  )
}

const App = (props) => {
  useEffect(() => {
    props.hideLoader();
  }, [props])

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#0758A8',
          },
        }}
      >
        <Router>
          <Layout>
            <Switch>
              <ScrollToTopRoute exact={true} path={"/"} component={Home} />
              <ScrollToTopRoute exact={true} path={"/product/:id?"} component={Product} />
              <ScrollToTopRoute exact={true} path={"/product/:categoryId/:id"} component={ProductDetail} />
              <ScrollToTopRoute exact={true} path={"/solution/:id?"} component={Solution} />
              <ScrollToTopRoute exact={true} path={"/solution/:categoryId/:id"} component={SolutionDetail} />
              <ScrollToTopRoute exact={true} path={"/news/:id?"} component={News} />
              <ScrollToTopRoute exact={true} path={"/news/:categoryId/:id"} component={NewsDetail} />
              <ScrollToTopRoute exact={true} path={"/innovation"} component={Innovation} />
              <ScrollToTopRoute exact={true} path={"/about/:id?"} component={About} />
              <ScrollToTopRoute exact={true} path={"/contact"} component={About} />
              <ScrollToTopRoute exact={true} path={"/search/:query?"} component={Search} />
              <ScrollToTopRoute exact={true} path={"/note"} component={Note} />
              <ScrollToTopRoute component={NotFound} />
            </Switch>
          </Layout>
        </Router>
      </ConfigProvider>
    </QueryClientProvider>
  );
}

export default App;
