import { Col, Row, Space } from "antd";
import React, { useState } from "react";

const SectionOneCard = ({ children, img }) => {
  return (
    <Row align="middle">
      <Col md={12} sm={24} xs={24}>
        {children}
      </Col>
      <Col md={12} sm={24} xs={24}>
        <img src={img} width="100%" />
      </Col>
    </Row>
  );
}

const SectionTwoCard = ({ children, img }) => {
  return (
    <Col lg={6} md={12} sm={24} xs={24}>
      <div>
        <img src={img} style={{ width: '100%' }}/>
        <div className="section-four-two-card-title">
          {children}
        </div>
      </div>
    </Col>
  );
}


const SectionFour = () => {
  const [tab, setTab] = useState(0);

  return (
    <div style={{ marginTop: '50px' }}>
      <Space size="middle" className="section-four-tabs">
        <div className={`section-four-tab ${tab === 0 ? 'active' : ''}`} onClick={() => setTab(0)}>场景验证实验室</div>
        <div className={`section-four-tab ${tab === 1 ? 'active' : ''}`} onClick={() => setTab(1)}>算法验证实验室</div>
        <div className={`section-four-tab ${tab === 2 ? 'active' : ''}`} onClick={() => setTab(2)}>构建系列柔性化验证平台</div>
      </Space>
      <div style={{ display: tab === 0 ? 'block' : 'none', marginTop: '50px' }}>
        <SectionOneCard img={require('../../../img/innovation/核心1.png')}>
          <div className="section-four-describe">
            <div>解决区域性前端概念原型验证供应链“丰富度、高效响应、定.制化服务”薄弱的痛点。</div>
            <div>·整合行业头部供应商资源，拥有供应链上百万种零部件目录供选择，具备提升硬件整体选型能力。</div>
            <div>·在自主光路设计、光源选型、镜头配置和定制、多源传感器融合等方面的快速设计能力和供应链能力。</div>
            <div>·针对多品种、多材质、多形状需求的机器视觉需求快速、精准验证能力。</div>
          </div>
        </SectionOneCard>
      </div>
      <div style={{ display: tab === 1 ? 'block' : 'none' }}>
        <SectionOneCard img={require('../../../img/innovation/核心2.png')}>
          <div className="section-four-describe">
            <div>解决模型“落地时间成本”及“终身训练成本”两个核心痛点。</div>
            <div>·建设集数据标注、模型开发测试、模型管理于一体的多种算法、场景库训练平台。</div>
            <div>·在精密加工、家电、半导体、铸造、汽车、纺织、集成电路、医疗、农业、3C、锂电、光伏等特定行业（包括山东省内优势行业）提供先验数据集和AI算法能力。</div>
            <div>
              <div>·算法库丰富</div>
              <div>a一般通用算法 b自主研发算法 c根据用户需求定制化研发（用户自主数据采集、样本标注、模型训练)。</div>
            </div>
            <div>
              <div>·多场景应用</div>
              <div>不断积累行业应用场景，解决多场景、复杂场景应用问题。</div>
            </div>
          </div>
        </SectionOneCard>
      </div>
      <div style={{ display: tab === 2 ? 'block' : 'none' }}>
        <Row gutter={[37, 37]} style={{ marginTop: '39px' }} justify="center">
          <SectionTwoCard img={require('../../../img/innovation/核心3-1.png')} >
            <div>基于工业机器人的</div>
            <div>三维形貌自动化测量系统验证平台</div>
          </SectionTwoCard>
          <SectionTwoCard img={require('../../../img/innovation/核心3-2.png')} >
            <div>精密缺陷检测系统</div>
            <div>测试验证平台</div>
          </SectionTwoCard>
          <SectionTwoCard img={require('../../../img/innovation/核心3-3.png')} >
            <div>亚微米级缺陷检测系统</div>
            <div>测试验证平台</div>
          </SectionTwoCard>
          <SectionTwoCard img={require('../../../img/innovation/核心3-4.png')} >
            <div>大视野缺陷检测系统</div>
            <div>测试验证平台</div>
          </SectionTwoCard>
        </Row>

      </div>
    </div>
  )
}

export default SectionFour;