import React from "react";

const NotFound = () => (
  <div >
    <section className="error_two_area">
      <div className="container flex">
        <div className="error_content_two text-center">
          <img src={require("../img/layout/error.png")} alt="" />
          <h2>错误。我们找不到你要的那一页。</h2>
          <p>
            很抱歉给您带来不便。请访问我们的主页
          </p>
          {/* <form action="#" className="search">
            <input type="text" className="form-control" placeholder="search" />
          </form> */}
          <a href="/" className="about_btn btn_hover">
            返回首页
          </a>
        </div>
      </div>
    </section>
  </div>
);
export default NotFound;
