import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

const SolutionItem = ({ data }) => {
  let { id } = useParams();
  let history = useHistory();

  const openDetail = () => {
    history.push(`/solution/${id}/${data.id}`)
  }

  return (
    <div className="solution-item" onClick={openDetail}>
      <div className="card">
        <div className="solution-item-img card-img-top" style={{ backgroundImage: `url(${data.pic_url})` }} />
        <div className="card-body">
          <p className="solution-item-text">{data.title}</p>
          <span className="solution-item-btn">查看详情 {'>'}</span>
        </div>
      </div>
    </div>
  )
}

export default SolutionItem;