import { Col, Row, Space } from "antd";
import React, { useState } from "react";

const Detail = ({ title, describe }) => {
  return (
    <div className="section-six-detail">
      <div className="section-six-title">{title}</div>
      <div className="divider section-six-divider"></div>
      <div className="section-six-describe">{describe}</div>
    </div>
  );
}


const SectionSix = () => {
  const [tab, setTab] = useState(0);

  return (
    <Row gutter={[30, 30]} justify="space-between" align="middle" style={{ marginTop: '50px' }}>
      <Col lg={10} sm={24} xs={24}>
        <Detail title="植根区域产业资源" describe="填补山东省内机器视觉行业短板，在机器视觉概念验证阶段形成供应全、报告快、成本低的核心竞争力，推动区域机器视觉行业竞争力的提升，推动机器视觉在制造业中的普及型应用。"/>
        <Detail title="形成典型场景方案" describe="聚焦十个制造型行业的典型场景和刚性需求，形成可落地的机器视觉解决方案和产品模块，面向同行业进行推广和复制，成为新旧动能转换的重要推手。"/>
        <Detail title="实现赋能千行百业" describe="同数百上千家机器视觉企业或自动化设备企业建立连接，动态赋能机器视觉企业降本增效，提高在全国的核心竞争力。"/>
        <Detail title="具备商业闭环能力" describe="机器视觉概念验证平台，具备自我商业闭环能力，在服务行业和社会的同时实现自身的商业成功。"/>
      </Col>
      <Col lg={14} sm={24} xs={24}>
        <img src={require('../../../img/innovation/生态体.png')} width="100%"/>
      </Col>
    </Row>
  )
}

export default SectionSix;