import React, { useMemo } from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import { useParams, Link, useHistory } from 'react-router-dom';
import { useCategory, useDetailData } from '../../hook/useBaseData';
import { Divider, Skeleton, Row, Col, List } from 'antd';
import dayjs from 'dayjs';

const NewsDetail = () => {
  let { categoryId, id } = useParams();
  const { data: category = [] } = useCategory(1);
  const { data, isLoading } = useDetailData(id)
  let history = useHistory();

  const openDetail = (id) => {
    history.push(`/news/0/${id}`)
  }

  const curCategoryId = useMemo(() => {
    if (~~categoryId) return categoryId;
    if (!data) return 0;
    return data.category_id;
  }, [data, categoryId])

  const curCategory = useMemo(() => {
    return category.find(item => {
      const findId = (child) => {
        if (child.id === curCategoryId) return true;
        if (!child._child) return false;
        for (let i = 0; i < child._child.length; i++) {
          const item = child._child[i];
          if (findId(item)) return true;
        }
      }
      return findId(item)
    })
  }, [category, curCategoryId])

  return (
    <div>
      <Breadcrumb breadcrumbClass="breadcrumb_area breadcrumb_area_three" imgName="banner/news.png" />
      <nav aria-label="breadcrumb" className="detail-breadcrumb">
        <ol className="breadcrumb container detail-breadcrumb-con">
          <li className="breadcrumb-item">
            <Link to="/">主页</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/news">产品中心</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/news/${curCategory && curCategory.id}`}>{curCategory && curCategory.name}</Link>
          </li>
          <li className="breadcrumb-item" aria-current="page">详情</li>
        </ol>
      </nav>
      <div className="container news-detail-cont">
        {isLoading ? <Skeleton active /> : (
          <Row gutter={30}>
            <Col lg={17} sm={24} xs={24}>
              <h1>{data.title}</h1>
              <div className="new-detail-time">{dayjs(data.update_time * 1000).format('YYYY-MM-DD')}</div>
              <Divider className="mt_10" />
                <div dangerouslySetInnerHTML={{ __html: data.content }} ></div>
              <Divider />
              <div className="d-flex justify-content-between">
                <div>
                  {data.prev && data.prev[0] && (
                    <div style={{ cursor: 'pointer' }} onClick={() => openDetail(data.prev[0].id)}>上一篇：{data.prev[0].title}</div>
                  )}
                </div>
                <div>
                  {data.next && data.next[0] && (
                    <div style={{ cursor: 'pointer' }} onClick={() => openDetail(data.next[0].id)}>下一篇：{data.next[0].title}</div>
                  )}
                </div>
              </div>
            </Col>
            <Col lg={7} sm={24} xs={24}>
              <List
                itemLayout="horizontal"
                dataSource={data.recommend || []}
                header={<h3>相关新闻</h3>}
                renderItem={(item) => (
                  <List.Item key={item.id}>
                    <List.Item.Meta
                      description={
                        <div style={{ cursor: 'pointer', color: '#666' }} onClick={() => openDetail(item.id)}>
                          <div>{item.create_time}</div>
                          <div>{item.title}</div>
                        </div>
                      }
                    />
                  </List.Item>
                )}
              />
            </Col>
          </Row>
        )}
      </div>
    </div>
  )
}

export default NewsDetail;