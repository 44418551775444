import React, { useMemo } from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import { useParams, Link } from 'react-router-dom';
import { useCategory, useDetailData } from '../../hook/useBaseData';
import DetailItem from './components/DetailItem';
import { Skeleton } from 'antd';
import DetailCard from './components/DetailCard';

const SolutionDetail = () => {
  let { categoryId, id } = useParams();
  const { data: category = [] } = useCategory(3);
  const { data, isLoading } = useDetailData(id)

  const curCategoryId = useMemo(() => {
    if (~~categoryId) return categoryId;
    if (!data) return 0;
    return data.category_id;
  }, [data, categoryId])

  const curCategory = useMemo(() => {
    return category.find(item => {
      const findId = (child) => {
        if (child.id === curCategoryId) return true;
        if (!child._child) return false;
        for (let i = 0; i < child._child.length; i++) {
          const item = child._child[i];
          if (findId(item)) return true;
        }
      }
      return findId(item)
    })
  }, [category, curCategoryId])

  return (
    <div>
      <Breadcrumb breadcrumbClass="breadcrumb_area breadcrumb_area_three" imgName="banner/solution.png" />
      <nav aria-label="breadcrumb" className="detail-breadcrumb">
        <ol className="breadcrumb container detail-breadcrumb-con">
          <li className="breadcrumb-item">
            <Link to="/">主页</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/solution">解决方案中心</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/solution/${curCategory && curCategory.id}`}>{curCategory && curCategory.name}</Link>
          </li>
          <li className="breadcrumb-item" aria-current="page">详情</li>
        </ol>
      </nav>
      <div className="container Solution-detail-cont">
        {isLoading ? <Skeleton active style={{ margin: '30px auto' }}/> :
          (
            <>
              <DetailCard data={data} />
              <div style={{ paddingBottom: '70px' }}>
                <DetailItem title={data.model_name_one} content={data.model_content_one} />
                <DetailItem title={data.model_name_two} content={data.model_content_two} />
                <DetailItem title={data.model_name_thr} content={data.model_content_thr} />
              </div>
            </>
          )
        }
      </div>
    </div>
  )
}

export default SolutionDetail;