import React from "react";
import { useAd } from "../../../hook/useBaseData";
import { Swiper, SwiperSlide, } from "swiper/react";
import SwiperCore,{Pagination,Autoplay} from 'swiper';


const HomeAd = () => {
  const { data = [] } = useAd();

  SwiperCore.use([Pagination,Autoplay]);

  const open = (url) => {
    window.open(url);
  }

  if (!data.length === 0) return null;

  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={0}
      initialSlide={1}
      mousewheel
      loop
      className="home-swiper"
      pagination={{ 
        clickable: true
      }} //开启分页器操作
      observer={true} //修改swiper自己或子元素时，自动初始化swiper(如果数据是请求下来的一定要重新初始化)
      observeParents={true} //修改swiper的父元素时，自动初始化swiper(如果数据是请求下来的一定要重新初始化)
      autoplay={true} //自动轮播开启
    >
      {
        data.map(item => (
          <SwiperSlide key={item.id}>
            <div onClick={() => open(item.url)} className="home_banner home_banner1" style={{ backgroundImage: `url(${item.pic_url})` }}>
            </div>
          </SwiperSlide>
        ))

      }
    </Swiper>
  )

}

export default HomeAd;