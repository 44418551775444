import React from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import './index.css'
import SectionDetail from './components/SectionDetail';
import { Row, Col } from 'antd';
import SectionOneCard from './components/SectionOneCard';
import SectionTwoCard from './components/SectionTwoCard';
import SectionFour from './components/SectionFour';
import SectionFive from './components/SectionFive';
import SectionSix from './components/SectionSix';


const Innovation = () => {
    return (
        <div>
            <Breadcrumb breadcrumbClass="breadcrumb_area breadcrumb_area_three" imgName="innovation/banner.png" />
            <div className=" innovation-container">
                <section className="container innovation-section">
                    <SectionDetail title="定位" content="以AI视觉模组为核心，构建机器视觉大脑，深度赋能山东制造业智能化升级" />
                    <Row gutter={[37, 37]} style={{ marginTop: '39px' }}>
                        <Col lg={6} md={12} sm={24} xs={24}>
                            <SectionOneCard title1="补强机器视觉" title2="产业核心点" img={require('../../img/innovation/定位图标1.png')} />
                        </Col>
                        <Col lg={6} md={12} sm={24} xs={24}>
                            <SectionOneCard title1="聚焦行业痛点" title2="形成产品线" img={require('../../img/innovation/定位图标2.png')} />
                        </Col>
                        <Col lg={6} md={12} sm={24} xs={24}>
                            <SectionOneCard title1="构建联合验证平台" title2="实现资源共享" img={require('../../img/innovation/定位图标3.png')} />
                        </Col>
                        <Col lg={6} md={12} sm={24} xs={24}>
                            <SectionOneCard title1="培育机器视觉" title2="产业生态集群" img={require('../../img/innovation/定位图标4.png')} />
                        </Col>
                    </Row>
                </section>
                <section className="innovation-section section-two">
                    <div className="container">
                        <SectionDetail title="目标" content="基于机器视觉概念验证平台及AI云脑，聚焦数十个山东省优势产业及战略性新型产业，落地上百个成熟验证的机器视觉方案和产品， 赋能上千家制造型企业，构建ZB级样本数据库，形成数据驱动的概念验证平台成长飞轮。" />
                        <Row gutter={[37, 37]} style={{ marginTop: '39px' }} justify="center">
                            <Col lg={8} md={12} sm={24} xs={24}>
                                <SectionTwoCard title1="1+1" title2="硬件平台 + AI电脑" img={require('../../img/innovation/目标1.png')} />
                            </Col>
                            <Col lg={8} md={12} sm={24} xs={24}>
                                <SectionTwoCard title1="10+" title2="垂直行业" img={require('../../img/innovation/目标2.png')} />
                            </Col>
                            <Col lg={8} md={12} sm={24} xs={24}>
                                <SectionTwoCard title1="100+" title2="方案验证" img={require('../../img/innovation/目标3.png')} />
                            </Col>
                            <Col lg={8} md={12} sm={24} xs={24}>
                                <SectionTwoCard title1="ZB+" title2="数据及样本" img={require('../../img/innovation/目标4.png')} />
                            </Col>
                            <Col lg={8} md={12} sm={24} xs={24}>
                                <SectionTwoCard title1="1000+" title2="赋能制造业" img={require('../../img/innovation/目标5.png')} />
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="container innovation-section">
                    <SectionDetail title="功能" content="机器视觉概念验证平台构建视觉场景验证实验室、算法实验室等创新技术验证平台，打造集产业融合、人才培养、成果展示、整体解决方案为一体的综合型平台。" />
                    <img alt='功能' src={require('../../img/innovation/功能.png')} style={{ width: '100%', marginTop: '39px' }} />
                </section>
                <section className="innovation-section section-four">
                    <div className="container">
                        <SectionDetail title="补强机器视觉产业核心点" />
                        <SectionFour />
                    </div>
                </section>
                <section className="container innovation-section section-five">
                    <SectionDetail title="聚焦行业" />
                    <SectionFive />
                </section>
                <section className="innovation-section section-six">
                    <div className="container">
                        <SectionDetail title="国内具有集聚效应的机器视觉产业生态体" />
                        <SectionSix />
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Innovation;