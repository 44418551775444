import React, { useState } from 'react';
import SolutionItem from './SolutionItem';
import { Button, Col, Pagination, Row, Skeleton } from 'antd';
import { useInformation } from '../../../hook/useBaseData';

const SolutionItems = ({ categoryId = 0 }) => {
  const [page, setPage] = useState(1);

  const { data: solutions, isLoading } = useInformation({ category_id: categoryId, p: page, r: 8, type: 3 }, { enabled: !!categoryId })

  if (isLoading) return <Skeleton active className="container" />

  return (
    <div>
      <Row gutter={[30, 30]}>
        {
          solutions?.list?.map(item => (
            <Col lg={6} sm={12} xs={24} key={item.id}>
              <SolutionItem key={item.id} data={item} />
            </Col>
          ))
        }
      </Row>

      <Pagination className="text-center mt_20" onChange={p => setPage(p)} pageSize={8} current={page} total={solutions.count || 0} showSizeChanger={false} itemRender={(page, type, originalElement) => {
        if (type === 'prev') return <Button type="text">上一页</Button>
        if (type === 'next') return <Button type="text">下一页</Button>
        return originalElement
      }} />
    </div>
  )
}

export default SolutionItems;