import React from 'react';
import { Link, useParams } from 'react-router-dom';


const PageHeader = ({ items = [], onChange = () => {} }) => {

  let { id } = useParams();
  return (
    <div className="page-header">
      <ul className="nav justify-content-end container">
        {
          items.map((item) => (
            <li className="nav-item" key={item.value} onClick={() => onChange(item.value)}>
              <Link className={`nav-link ${id === `${item.value}` ? 'active' : ''}`} to={item.to}>{item.label}</Link>
            </li>
          ))
        }
      </ul>
    </div>
  )
}

export default PageHeader;