import React, { useState } from 'react';
import './index.css'
import { useParams } from 'react-router-dom';
import Item from './components/Item';
import { useInformation } from '../../hook/useBaseData';
import { Pagination, Button } from 'antd';

const Search = () => {
    let { query } = useParams();

    const [page, setPage] = useState(1);

    const { data: queryData = {} } = useInformation({ name: query, r: 10, p: page })

    const pageChange = (page) => {
        setPage(page)
        window.scrollTo(0, 0)
    }

    return (
        <div className="container page-container">
            <div className="search-title"><mark className="search-mark">“{query}”</mark>搜索结果</div>
            <div>
                <div>
                    {
                        queryData && queryData.list && queryData.list.map(item => (
                            <div className="pt_30" key={item.id}>
                                <Item data={item} />
                            </div>
                        ))
                    }
                </div>
                <Pagination className="text-center mt_20" onChange={p => pageChange(p)} pageSize={10} current={page} total={queryData.count || 0} showSizeChanger={false} itemRender={(page, type, originalElement) => {
                    if (type === 'prev') return <Button type="text">上一页</Button>
                    if (type === 'next') return <Button type="text">下一页</Button>
                    return originalElement
                }}/>
            </div>
        </div>
    )
}

export default Search;