import React, { useMemo } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { useMenuData, useContactData } from '../../hook/useMenuData';
import './index.css';
import { Input, Modal } from 'antd';
import { SearchOutlined } from '@ant-design/icons'
import Sticky from 'react-stickynode';

const MenuItem = ({ widget = [], className = '' }) => {
    const hasRow = useMemo(() => {
        return !!widget.find(item => item._child)
    }, [widget])
    if (!hasRow) {
        return (
            widget.map(item => {
                return <li className={`nav-item ${className}`} key={item.id}><NavLink exact className="nav-link" to={item.url}>{item.name}</NavLink></li>
            })
        )
    }
    return (
        <li className={`d-flex ${widget.length > 4 ? 'flex-wrap' : ''} nav-item mx-md-n3 ${className}`}>
            {
                widget.map(item => {
                    return (
                        <div key={item.id} className="px-md-3">
                            <NavLink className="nav-link" to={item.url}>{item.name}</NavLink>
                            <div className="divider" style={{ borderColor: 'var(--primary)' }}></div>
                            <ul className="pl-0">
                                <MenuItem widget={item._child} className="px-0 py-0" />
                            </ul>
                        </div>
                    )
                })
            }
        </li>
    )
}

const CustomNavbar = (props) => {
    var { cClass } = props;
    const { data } = useMenuData();
    const { data: contactData = {} } = useContactData();
    const history = useHistory();
    const [searchValue, setSearchValue] = React.useState('');
    const inputRef = React.useRef(null);

    const menuData = useMemo(() => {
        if (!data || !data) return [];
        return data.top.map(item => {
            if (!item._child) return item;
            const hasRow = !!item._child.find(child => child._child)
            if (!hasRow) return item;
            return {
                ...item,
                hasRow: true,
            }

        })
    }, [data])

    const [searchOpen, setSearchOpen] = React.useState(false);
    const openSearchPage = () => {
        searchValue && history.push(`/search/${searchValue}`)
        closeSearchModal()
    }

    const openSearchModal = () => {
        setSearchOpen(() => true)
        setTimeout(() => {
            inputRef.current.focus()
        }, 100)
    }

    const closeSearchModal = () => {
        setSearchOpen(() => false)
        setSearchValue(() => '')
    }

    return (
        <>
            <Sticky top={0} innerZ={20} innerClass="test" className="tt" activeClass="navbar_fixed" style={{ height: 0 }}>
                <header className="header_area">
                    <nav className={`navbar navbar-expand-lg menu_one`}>
                        <div className={`container ${cClass}`}>
                            <Link className={`navbar-brand`} to="/">
                                <img className="navber-logo" src={require("../../img/layout/logo.png")} alt="logo" />
                                <span className="f_size_24 pl_10" style={{ color: '#333' }}>深圳智机</span>
                                <div className="divider divider-vertical" style={{ borderColor: '#333', height: '24px' }}></div>
                                <span className="f_size_16 navber-slogan" style={{ color: '#999' }}>AI工业质检专家</span>
                            </Link>
                            <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="menu_toggle">
                                    <span className="hamburger">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </span>
                                    <span className="hamburger-cross">
                                        <span></span>
                                        <span></span>
                                    </span>
                                </span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className={`navbar-nav menu ml-auto`}>
                                    {
                                        menuData.map(widget => {
                                            if (!widget._child) {
                                                return <li className="nav-item" key={widget.id}><NavLink exact={widget.url === '/' || widget.url === ''} className="nav-link" to={widget.url}>{widget.name}</NavLink></li>
                                            }
                                            return (
                                                <li className={`dropdown submenu nav-item ${(widget.hasRow && widget._child.length > 4 && 'mega_menu') || ''}`} key={widget.id}>
                                                    <NavLink className="nav-link dropdown-toggle" to={widget.url}>
                                                        {/* <Link to="./" className="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> */}
                                                        {widget.name}
                                                    </NavLink>

                                                    <div className="mega_menu_inner">
                                                        <ul className="dropdown-menu">
                                                            <MenuItem widget={widget._child} className="menu-content" />
                                                        </ul>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                    <li className="nav-item">
                                        <div className="divider divider-vertical menu-divider"></div>
                                    </li>
                                    <li className="nav-item">
                                        {/* <Link to="./" className="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> */}
                                        <div className="menu-search-icon" onClick={() => openSearchModal()}></div>
                                        {/* </Link> */}
                                    </li>
                                </ul>
                                <a className="ml_30 d-flex" href={`tel:${contactData.landline}`}>
                                    <div className="header-phone-label">服务 热线</div>
                                    <div className="ml_10">{contactData.landline}</div>
                                </a>
                            </div>
                        </div>
                    </nav>
                </header>
            </Sticky>
            <Modal
                wrapClassName="search-modal-wrap"
                style={{ top: '10px', padding: 0 }}
                bodyStyle={{ padding: 0 }}
                closeIcon={false}
                footer={false}
                open={searchOpen}
                maskClosable
                onCancel={() => closeSearchModal()}
            >
                <Input
                    ref={inputRef}
                    value={searchValue}
                    onChange={e => setSearchValue(e.target.value)}
                    placeholder="请输入关键字"
                    bordered={false}
                    onPressEnter={openSearchPage}
                    suffix={<SearchOutlined onClick={openSearchPage} style={{ cursor: 'pointer' }} />}
                />
            </Modal>
        </>
    );
}

export default CustomNavbar;