import React from "react";
import Title from "../../../components/Title";
import { Col, Row } from "antd";
import { useContactData } from "../../../hook/useMenuData";

const Contact = () => {
  const { data = {} } = useContactData();

  return (
    <div className="contact container page-container">
      <Title>招贤纳才</Title>
      <Row className="contact-section-one" gutter={[0, 30]} justify="center">
        <Col lg={8} sm={12} xs={24} className="contact-section-one-card">
          <div className="contact-title">联系电话</div>
          <div className="contact-detail">{data.landline}</div>
        </Col>
        <Col lg={8} sm={12} xs={24} className="contact-section-one-card">
          <div className="contact-title">联系电话</div>
          <div className="contact-detail">{data.mobile_phone}</div>
        </Col>
        <Col lg={8} sm={12} xs={24} className="contact-section-one-card">
          <div className="contact-title">邮箱</div>
          <div className="contact-detail">{data.email}</div>
        </Col>
      </Row>
      <a href={data.boss_url} className="contact-btn">BOSS直聘<span className="contact-btn-icon"></span></a>
      <Title className="mt_60">办公环境</Title>
      <Row className="mt_60" gutter={[40, 30]} justify="center">
        <Col lg={8} sm={12} xs={24}><img src={require('../../../img/about/图片×1.png')} width="100%" /></Col>
        <Col lg={8} sm={12} xs={24}><img src={require('../../../img/about/图片×1(1).png')} width="100%" /></Col>
        <Col lg={8} sm={12} xs={24}><img src={require('../../../img/about/图片×1(2).png')} width="100%" /></Col>
      </Row>
    </div>
  )
}

export default Contact;