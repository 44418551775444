import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

export const useNote = () => {
  return useMutation({
    mutationKey: ['note'],
    mutationFn: (data) => axios.post(`${window.webConfig.api}/Api/index/message`, data, { headers: { "Content-Type": "multipart/form-data" }, }).then(res => {
      if (res.data.code !== 1) {
        return Promise.reject(res.data.message || 'No menu data');
      }
      return res.data;
    }),
  });
};
