import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import './index.css'
import PageHeader from '../../components/PageHeader';
import { useParams, useHistory } from 'react-router-dom';
import NewsItem from './components/NewsItem';
import { useCategory, useInformation } from '../../hook/useBaseData';
import { Pagination, Button, Skeleton } from 'antd';

const News = () => {
    let { id } = useParams();
    const [page, setPage] = useState(1);
    let history = useHistory();
    const { data: category = [] } = useCategory(1, {
        formatResult: (data) => {
            return data.map(item => ({ label: item.name, value: item.id, to: `/news/${item.id}` }))
        }
    });

    useEffect(() => {
        setPage(1)
    }, [id])

    const { data: news = {}, isLoading } = useInformation({ category_id: id, r: 5, p: page, type: 1 }, { enabled: !!id })

    useEffect(() => {
        if (!category.length || id) return;
        history.replace(category[0].to)
    }, [id, category, history])

    return (
        <div>
            <Breadcrumb breadcrumbClass="breadcrumb_area breadcrumb_area_three" imgName="banner/news.png" />
            <PageHeader items={category}/>
            { isLoading && <Skeleton active className="container" style={{ margin: '30px auto' }}/> }
            <div className="container news-container">
                <div>
                    {
                        news && news.list && news.list.map(item => (
                            <div className="pt_30" key={item.id}>
                                <NewsItem data={item} />
                            </div>
                        ))
                    }
                </div>
                <Pagination className="text-center mt_20" onChange={p => setPage(p)} pageSize={5} current={page} total={news.count || 0} showSizeChanger={false} itemRender={(page, type, originalElement) => {
                    if (type === 'prev') return <Button type="text">上一页</Button>
                    if (type === 'next') return <Button type="text">下一页</Button>
                    return originalElement
                }}/>
            </div>
        </div>
    )
}

export default News;