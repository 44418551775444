import React, { useState } from "react";
import Title from "../../../components/Title";
import { Row, Col } from "antd";
import { useContactData } from "../../../hook/useMenuData";
import { Swiper, SwiperSlide } from "swiper/react";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";


const SectionCard = ({ describes, img }) => {
  return (
    <Row align="middle" gutter={[30, 30]} className="mt_50">
      <Col md={12} sm={24} xs={24}>
        <img src={img} width="100%" />
      </Col>
      <Col md={12} sm={24} xs={24}>
        <div className="company-describe">
          <div>深圳智机视觉科技有限公司成立于2019年，位于深圳市银星智界一期国家级科技园内，由知名科学家和产业投资人联合创立，核心团队来自于一流科研机构和优秀企业，致力于“让千万机器带上眼睛和大脑，推动工业向智能化升级”。公司产品主要聚焦核心制造领域机器视觉的关键难点和“卡脖子”问题，整合全球优质研发资源，构建有竞争力的新一代工业AI产品。</div>
          <div>公司目前主要聚焦精密加工、3C、半导体、汽车零部件等行业。主要服务于手机、汽车电子、智能穿戴式等产品的全球顶级龙头企业；公司在成长过程中，获得了一线投资机构的战略投资，是华为云战略合作伙伴单位，是山东产业技术研究院机器视觉概念验证中心的主要运营单位，是华南机器视觉联盟理事单位。</div>
          <div>公司始终坚持“以客户为中心”的宗旨，聚焦于解决机器视觉行业难题，坚持做难而正确的事。全力打造学习型组织，以人 才成长驱动企业发展、以创造价值引领技术创新。</div>
        </div>
      </Col>
    </Row>
  );
}

const Company = () => {
  const { data = {} } = useContactData();
  const [swiperRef, setSwiperRef] = useState(null);
  window.swiperRef = swiperRef;

  const goNext = () => {
    swiperRef.slideNext();
  }

  const goPrev = () => {
    swiperRef.slidePrev();
  }

  const copyright = [
    { key: 1, img: require('../../../img/about/CPA20227742CN实用新型专利证书(签章)-min.jpg'), label: '专利证书' },
    { key: 2, img: require('../../../img/about/CPA20227744CN实用新型专利证书(签章)-min.jpg'), label: '专利证书' },
    { key: 3, img: require('../../../img/about/CPA20227745CN实用新型专利证书(签章)-min.jpg'), label: '专利证书' },
    { key: 4, img: require('../../../img/about/中关村高新技术企业证书-min.jpg'), label: '高新技术企业证书' },
    { key: 5, img: require('../../../img/about/计算机软件著作权登记证书-按键检测软件v1.0（深圳智机）-min.jpg'), label: '软件著作' },
    { key: 6, img: require('../../../img/about/计算机软件著作权登记证书-表壳检测软件v1.0（深圳智机）-min.jpg'), label: '软件著作' },
    { key: 7, img: require('../../../img/about/计算机软件著作权登记证书-连接器检测软件v1.0（深圳智机）-min.jpg'), label: '软件著作' },
    { key: 8, img: require('../../../img/about/计算机软件著作权登记证书-涂胶检测软件（北京智机）-min.jpg'), label: '软件著作' },
    { key: 9, img: require('../../../img/about/计算机软件著作权登记证书-外环检测软件v1.0（深圳智机）-min.jpg'), label: '软件著作' },
    { key: 10, img: require('../../../img/about/计算机软件著作权登记证书-小螺柱检测软件v1.0（深圳智机）-min.jpg'), label: '软件著作' },
    { key: 11, img: require('../../../img/about/计算机软件著作权登记证书-小圆片检测软件v1.0（深圳智机）-min.jpg'), label: '软件著作' },
    { key: 12, img: require('../../../img/about/计算机软件著作权登记证书-支架检测软件v1.0（深圳智机）-min.jpg'), label: '软件著作' },
    { key: 13, img: require('../../../img/about/键卡螺环支架检测系统软件V1.0-min.jpg'), label: '软件著作' },
    { key: 14, img: require('../../../img/about/深圳智机视觉科技有限公司Q证书扫描件-min.jpg'), label: '质量管理体系' },
    { key: 15, img: require('../../../img/about/外观设计专利证书-胶条扫描检测装置（杭州兰亭视觉科技有限责任公司）-min.jpg'), label: '外观专利' },
  ]

  const partner = [
    require('../../../img/about/按钮1.png'),
    require('../../../img/about/按钮2.png'),
    require('../../../img/about/按钮3.png'),
    require('../../../img/about/按钮4.png'),
    require('../../../img/about/按钮5.png'),
    require('../../../img/about/按钮6.png'),
    require('../../../img/about/按钮7.png'),
    require('../../../img/about/按钮8.png'),
    require('../../../img/about/按钮9.png'),
    require('../../../img/about/按钮10.png'),
    require('../../../img/about/按钮11.png'),
    require('../../../img/about/按钮12.png'),
    require('../../../img/about/按钮13.png'),
    require('../../../img/about/按钮14.png'),
    require('../../../img/about/按钮15.png'),
    require('../../../img/about/按钮16.png'),
    require('../../../img/about/按钮17.png'),
    require('../../../img/about/按钮18.png'),
    require('../../../img/about/按钮19.png'),
    require('../../../img/about/按钮20.png'),
    require('../../../img/about/按钮21.png'),
    require('../../../img/about/按钮22.png'),
    require('../../../img/about/按钮23.png'),
    require('../../../img/about/按钮24.png'),
  ]

  return (
    <div className="company page-container">
      <div className="container">
        <Title>公司介绍</Title>
        <SectionCard img={require('../../../img/about/公司介绍.png')} />
        <Title className="mt_50">发展历程</Title>
        <div className="text-center company-describe2 mt_50">
          <div>以AI大模型为抓手，让千万机器带上眼睛，</div>
          <div>重构机器的视觉大脑和群体智能</div>
        </div>
        <img className="mt_50" src={require('../../../img/about/发展历程(1).png')} width="100%" />
        <Title className="mt_50">智机使命</Title>
        <img className="mt_50" src={require('../../../img/about/发展历程.png')} width="100%" />
        <div className="text-center company-describe2 mt_50">
          <div className="f_size_16">让千万机器带上眼睛，重构机器的视觉大脑和群体智能，引领机器人智能革命！</div>
          <div className="mt_10 f_size_24">AI工业质检专家</div>
        </div>
      </div>
      <div className="software-copyright-con mt_60">
        <div className="container">
          <Title>专利著作</Title>
          <div className="software-copyright-one mt_50" >
            <div className="software-copyright-one-item" >
              <div>华为产业云AI生态</div>
              <div>战略合作伙伴</div>
            </div>
            <div className="software-copyright-one-item" >
              <div>中关村高新技术企业</div>
            </div>
            <div className="software-copyright-one-item" >
              <div>华南机器视觉联盟</div>
              <div>理事单位</div>
            </div>
            <div className="software-copyright-one-item" >
              <div>“科创中国”</div>
              <div>创新创业大赛</div>
              <div>全国百强（2022）</div>
            </div>
          </div>
          <div className="copyright-swiper-c">
            <div className="copyright-swiper-n copyright-swiper-l" onClick={() => goPrev()}><LeftCircleOutlined /></div>
            <div className="copyright-swiper-m">
              <Swiper
                slidesPerView="auto"
                spaceBetween={30}
                className="copyright-swiper"
                onSwiper={setSwiperRef}
              >
                {
                  copyright.map(item => (
                    <SwiperSlide key={item.key} style={{ width: 'auto' }}>
                      <img src={item.img} style={{ height: '135px' }} />
                      <div className="mt_10">{item.label}</div>
                    </SwiperSlide>
                  ))
                }
              </Swiper>
            </div>
            <div className="copyright-swiper-n copyright-swiper-r" onClick={() => goNext()}><RightCircleOutlined /></div>
          </div>
        </div>
      </div>
      <div className="container mt_60">
        <Title>合作伙伴</Title>
        <div className="company-partner mt_50">
          {
            partner.map((item, index) => (
              <img src={item} width="270px" key={index} className="mb_40 company-partner-item"/>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default Company;