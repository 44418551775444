import React from 'react';
import { Row, Col } from 'antd'; import { useParams, useHistory } from 'react-router-dom';

const NewsItem = ({ data }) => {
  let { id } = useParams();
  let history = useHistory();

  const openDetail = () => {
    history.push(`/news/${id}/${data.id}`)
  }

  return (
    <Row className="news-item" onClick={openDetail}>
      <Col sm={24} md={10} lg={8} className="news-item-l">
        <div className="news-item-img" style={{ backgroundImage: `url(${data.pic_url})` }}></div>
      </Col>
      <Col sm={24} md={14} lg={16} className="news-item-r">
        <div className="news-item-rc">
          <h3 className="news-item-title">{data.title}</h3>
          <p className="news-item-detail">{data.describe}</p>
          <span className="news-item-btn">查看详情 {'>'}</span>
        </div>
      </Col>
    </Row>
  )
}

export default NewsItem;