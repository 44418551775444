import React, { Component, useMemo } from 'react';
import { Link } from 'react-router-dom';
import Reveal from 'react-reveal/Reveal'
import { useMenuData, useContactData } from '../../hook/useMenuData';
import { Col, Row } from 'antd';

const Footer = () => {
    const { data } = useMenuData();

    const { data: contactData = {} } = useContactData();

    const footerData = useMemo(() => {
        if (!data) return [];
        return data.bottom;
    }, [data])
    return (
        <footer className="new_footer_area">
            <div className="new_footer_top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 row">
                            {
                                footerData.map(widget => {
                                    return (
                                        <Reveal effect="fadeInLeft" duration={500} key={widget.id}>
                                            <div className="col-lg-2 .f_widget.about-widget .f_list li a col-md-6">
                                                <div className="f_widget about-widget wow fadeInLeft" data-wow-delay="0.4s">
                                                    <h3 className="f-title f_600 t_color f_size_18">
                                                        <Link to={widget.url.trim()}>{widget.name}</Link>
                                                    </h3>
                                                    <div className="divider"></div>
                                                    <ul className="list-unstyled f_list">
                                                        {
                                                            widget._child.map(item => {
                                                                if (item._child) {
                                                                    return (
                                                                        <React.Fragment key={item.id}>
                                                                            <li><span className="text-white" style={{ borderBottom: '1px solid #fff' }}><Link to={item.url.trim()}>{item.name}</Link></span></li>
                                                                            {
                                                                                item._child.map(child => <li key={child.id}><Link to={child.url.trim()}>{child.name}</Link></li>)
                                                                            }
                                                                        </React.Fragment>
                                                                    )
                                                                }
                                                                return (
                                                                    <li key={item.id}><Link to={item.url.trim()}>{item.name}</Link></li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </Reveal>
                                    )
                                })
                            }
                        </div>
                        <Reveal effect="fadeInRight" duration={500} key="logo">
                            <div className="col-lg-3 col-md-6">
                                <div className="f_widget social-widget wow fadeInLeft text-center" data-wow-delay="0.4s">
                                    <h6 className="f-title f_600 t_color f_size_15 mb-30">服务热线</h6>
                                    <h3 className="f-title f_600 t_color f_size_32">{contactData.landline}</h3>
                                    <h3 className="f-title f_600 t_color f_size_32">{contactData.mobile_phone}</h3>
                                    <Row gutter={10}>
                                        <Col>
                                            <div className="f_qrcode mx-auto mt_40" style={{ backgroundImage: `url(${require ("../../img/layout/二维码_微信公众号_深圳智机.jpg")})` }}></div>
                                            <p className="f-title mt_20 f_size_12">关注公众号</p>
                                        </Col>
                                        <Col>
                                            <div className="f_qrcode mx-auto mt_40" style={{ backgroundImage: `url(${contactData.qr_code})` }}></div>
                                            <p className="f-title mt_20 f_size_12">客服微信</p>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Reveal>
                    </div>
                </div>
            </div>
            <div className="footer_bottom">
                <div className="container">
                    <div className="row align-items-center" style={{ height: '58px' }}>
                        <div className="col-lg-6 col-sm-7">
                            <p className="mb-0 f_400">深圳智机视觉科技有限公司   |   青岛机器视觉概念验证中心</p>
                        </div>
                        <div className="col-lg-6 col-sm-5 text-right">
                            <p>Shenzhen Zhiji Vision Technology Co.,Ltd.   |  <a href="https://beian.miit.gov.cn/" style={{ color: '#fff' }} target="_blank">粤ICP备2023086333号-1</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default Footer;