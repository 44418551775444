import React from "react";

const SectionOneCard = ({ title1, title2, img }) => {
  return (
    <div className="section-one-card">
      <div className="section-one-card-img" style={{ backgroundImage: `url(${img})` }}>
      </div>
      <div className="section-one-card-title">
        <div>{title1}</div>
        <div>{title2}</div>
      </div>
    </div>
  );
}

export default SectionOneCard;
